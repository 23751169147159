import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useContext } from "react";
import { RoomContext } from "../roomContext";
import { FaChild, FaShieldAlt } from "react-icons/fa";

const ChildrenCarePage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const context = useContext(RoomContext);
  const { polish } = context;
  return (
    <Wrapper>
      <div className="title">
        <h1>
          <FaShieldAlt />
        </h1>
        <h2>
          <FaChild />
        </h2>
        <h3>
          <FaShieldAlt />
        </h3>

        <h2 data-aos="fade-up">
          {polish ? "Procedura Ochrony Dzieci" : "Child Protection Procedure"}{" "}
          <br />
        </h2>
        <h3>
          <FaShieldAlt />
        </h3>
        <h2>
          <FaChild />
        </h2>
        <h1>
          <FaShieldAlt />
        </h1>
      </div>
      {polish ? (
        <div className="content">
          <h2>PROCEDURY OCHRONY DZIECI W HOTELU MIODOWA***</h2>
          <h3>
            Mając na uwadze treść wytycznych Organizacji Narodów Zjednoczonych w
            zakresie biznesu i praw człowieka, uznając istotną rolę biznesu w
            zapewnieniu poszanowania praw dzieci, w szczególności prawa do
            ochrony ich godności i wolności od wszelkich form krzywdzenia, Hotel
            Miodowa*** przyjmuje niniejszy dokument jako wzór zasad i procedur w
            przypadku podejrzenia, że dziecku, które przebywa w hotelu, dzieje
            się krzywda oraz zapobiegania takim zagrożeniom. Politykę ochrony
            dzieci w naszym hotelu będziemy realizować poprzez niniejsze zasady.
          </h3>
          <p>
            1. Hotel Miodowa*** prowadzi swoją działalność operacyjną z
            najwyższym poszanowaniem praw człowieka, w szczególności praw dzieci
            jako osób szczególnie wrażliwych na krzywdzenie.
          </p>
          <p>
            2. Hotel Miodowa*** uznaje swoją rolę w prowadzeniu biznesu
            społecznie odpowiedzialnego i promowaniu pożądanych postaw
            społecznych.
          </p>
          <p>
            3. Hotel Miodowa*** w szczególności podkreśla istotność prawnego i
            społecznego obowiązku zawiadamiania organów ścigania o każdym
            przypadku podejrzenia popełnienia przestępstwa na szkodę dzieci i
            zobowiązuje się szkolić swój personel w tym zakresie.
          </p>
          <p>
            4. Hotel Miodowa*** zobowiązuje się edukować personel na temat
            okoliczności wskazujących, że dziecko przebywające w obiekcie może
            być krzywdzone oraz w zakresie sposobów szybkiego i odpowiedniego
            reagowania na takie sytuacje.
          </p>
          <p>
            5. Jedną z form skutecznego zapobiegania krzywdzeniu dzieci jest
            identyfikacja dziecka przebywającego w obiekcie i jego/jej relacji w
            stosunku do osoby dorosłej, z którą przebywa w obiekcie. Personel
            podejmuje wszelkie możliwe kroki zmierzające do przeprowadzenia
            identyfikacji dziecka i jego relacji z osobą dorosłą, z którą
            znajduje się w obiekcie.
          </p>
          <h3>PROCEDURA W PRZYPADKU PODEJRZENIA KRZYWDZENIA DZIECKA</h3>
          <p>
            1. Zawsze, kiedy jest to możliwe, należy dokonywać identyfikacji
            dziecka i jego/jej relacji z osobą dorosłą, z którą przebywa w
            obiekcie.
          </p>
          <p>
            2. W sytuacjach nietypowych i/lub podejrzanych, wskazujących na
            możliwe ryzyko krzywdzenia dziecka, identyfikacja jest obowiązkowo
            przeprowadzana przez pracownika recepcji.
          </p>
          <p>
            3. Aby dokonać identyfikacji dziecka i jego/jej relacji w stosunku
            do osoby, z którą przebywa w obiekcie, należy:
          </p>
          <p className="podpunkt">
            a). Zapytać o tożsamość dziecka oraz o relację dziecka w stosunku do
            osoby, z którą przybyło do obiektu lub w nim przebywa. W tym celu
            można poprosić o dokument tożsamości dziecka lub inny dokument
            potwierdzający, że osoba dorosła ma prawo do sprawowania opieki nad
            dzieckiem w obiekcie. Wykaz przykładowych dokumentów został wskazany
            w przypisie poniżej. W przypadku braku dokumentu tożsamości można
            poprosić o podanie danych dziecka (imię, nazwisko, adres, numer
            PESEL).
          </p>
          <p className="podpunkt">
            b). W przypadku braku dokumentów wskazujących na pokrewieństwo
            dziecka i osoby dorosłej należy zapytać o tę relację osobę dorosłą
            oraz dziecko.
          </p>
          <p className="podpunkt">
            c). Jeżeli osoba dorosła nie jest rodzicem lub opiekunem prawnym
            dziecka, należy zapytać, czy posiada dokument świadczący o zgodzie
            rodziców na wspólny wyjazd osoby dorosłej z dzieckiem (np. pisemne
            oświadczenie).
          </p>
          <p className="podpunkt">
            d). Jeżeli osoba dorosła nie posiada dokumentu zgody rodziców,
            należy poprosić o numer telefonu wyżej wymienionych, aby zadzwonić i
            potwierdzić przebywanie dziecka w obiekcie z obcą osobą dorosłą za
            wiedzą i zgodą rodziców/opiekunów prawnych.
          </p>
          <p>
            4. W przypadku oporu ze strony osoby dorosłej co do okazania
            dokumentu dziecka i/lub wskazania relacji należy wyjaśnić, że
            procedura służy zapewnieniu dzieciom korzystającym z Hotel
            Miodowa*** bezpieczeństwa i została opracowana w porozumieniu z
            organizacjami pozarządowymi działającymi w tym przedmiocie.
          </p>
          <p>
            5. Po wyjaśnieniu sprawy w sposób pozytywny należy podziękować za
            czas poświęcony na upewnienie się, że dziecko jest pod dobrą opieką
            i jeszcze raz podkreślić, że procedura ma na celu zapewnienie
            bezpieczeństwa dzieciom.
          </p>
          <p>
            6. W przypadku gdy rozmowa nie rozwieje wątpliwości dotyczących
            podejrzenia wobec dorosłego i jego intencji skrzywdzenia dziecka,
            należy dyskretnie powiadomić przełożonego i pracowników ochrony (o
            ile są w tym czasie na terenie obiektu). Aby nie wzbudzać podejrzeń,
            można np. powołać się na konieczność skorzystania ze sprzętów na
            tyłach recepcji, prosząc osobę dorosłą, aby poczekała wraz z
            dzieckiem w holu, restauracji lub innym miejscu.
          </p>
          <p>
            7. Od momentu kiedy pojawiły się pierwsze wątpliwości, zarówno
            dziecko, jak i osoba dorosła powinni być pod stałą obserwacją
            personelu i nie zostawać sami.
          </p>
          <p>
            8. Przełożony, który został powiadomiony o sytuacji, podejmuje
            decyzję o zawiadomieniu policji lub w razie wątpliwości przejmuje
            rozmowę z podejrzaną osobą dorosłą w celu uzyskania dalszych
            wyjaśnień.
          </p>
          <p>
            9. W przypadku gdy rozmowa potwierdzi przekonanie o próbie lub
            popełnieniu przestępstwa na szkodę dziecka, przełożony zawiadamia o
            tym fakcie policję. Dalej stosuje się procedurę w przypadku
            okoliczności wskazujących na skrzywdzenie dziecka.
          </p>
          <p>
            10. W przypadku gdy świadkami nietypowych i/lub podejrzanych
            sytuacji są pracownicy innych działów Hotelu Miodowa*** np. serwis
            sprzątający, obsługa pokoi, pracownicy baru i restauracji, strefy
            relaksu, ochrony i in., powinni oni o tym niezwłocznie zawiadomić
            przełożonego, który zadecyduje o podjęciu odpowiednich działań.
          </p>
          <p>
            11. W zależności od sytuacji i miejsca przełożony weryfikuje, na ile
            podejrzenie krzywdzenia dziecka jest zasadne. W tym celu dobiera
            odpowiednie środki prowadzące do wyjaśnienia sytuacji lub podejmuje
            decyzję o przeprowadzeniu interwencji i zawiadamia policję.
          </p>
          <h3>
            PROCEDURA W PRZYPADKU OKOLICZNOŚCI WSKAZUJĄCYCH NA SKRZYWDZENIE
            DZIECKA
          </h3>
          <p>
            1. Mając uzasadnione podejrzenie, że dziecko przebywające w obiekcie
            jest krzywdzone, należy niezwłocznie zawiadomić policję, dzwoniąc
            pod numer 112 i opisując okoliczności zdarzenia. W zależności od
            dynamiki sytuacji i okoliczności, telefon wykonuje osoba, która jest
            bezpośrednim świadkiem zdarzenia (pracownik/przełożony). Jeśli
            zawiadamiającym jest pracownik, jednocześnie o zdarzeniu informuje
            swojego przełożonego.
          </p>
          <p>
            2. Uzasadnione podejrzenie krzywdzenia dziecka występuje wtedy, gdy:
          </p>
          <p className="podpunkt">
            a). dziecko ujawniło pracownikowi obiektu fakt krzywdzenia,
          </p>
          <p className="podpunkt">b). pracownik zaobserwował krzywdzenie,</p>
          <p className="podpunkt">
            c). dziecko ma na sobie ślady krzywdzenia (np. zadrapania,
            zasinienia), a zapytane odpowiada niespójnie i/lub chaotycznie lub/i
            popada w zakłopotanie bądź występują inne okoliczności mogące
            wskazywać na krzywdzenie np. znalezienie materiałów pornograficznych
            z udziałem dzieci w pokoju osoby dorosłej.
          </p>
          <p>
            3. W tej sytuacji należy uniemożliwić dziecku oraz osobie
            podejrzewanej o krzywdzenie dziecka oddalenie się z obiektu.
          </p>
          <p>
            4. W uzasadnionych przypadkach można dokonać obywatelskiego
            zatrzymania osoby podejrzewanej. W takiej sytuacji, do czasu
            przybycia policji należy trzymać tę osobę pod nadzorem dwóch
            pracowników w osobnym pomieszczeniu z dala od widoku innych gości.
          </p>
          <p>
            5. W każdym przypadku należy zadbać o bezpieczeństwo dziecka.
            Dziecko powinno przebywać pod opieką pracownika do czasu przyjazdu
            policji.
          </p>
          <p>
            6. W przypadku uzasadnionego podejrzenia, że doszło do popełnienia
            przestępstwa powiązanego z kontaktem dziecka z materiałem
            biologicznym sprawcy (sperma, ślina, naskórek), należy w miarę
            możliwości nie dopuścić, aby dziecko myło się oraz jadło/piło do
            czasu przyjazdu policji.
          </p>
          <p>
            7. Po odbiorze dziecka przez policję należy zabezpieczyć materiał z
            monitoringu oraz inne istotne dowody (np. dokumenty) dotyczące
            zdarzenia i na wniosek służb przekazać ich kopię listem poleconym
            lub osobiście prokuratorowi lub policji.
          </p>
          <p>
            8. Po interwencji należy opisać zdarzenie w dzienniku zdarzeń lub
            innym dokumencie przeznaczonym do tego celu.
          </p>
          <h3>ZATRUDNIANIE OSÓB DO PRACY Z DZEĆMI</h3>
          <p>
            1. Wszystkie osoby pracujące z dziećmi muszą być dla nich
            bezpieczne, co oznacza m.in., że ich historia zatrudnienia powinna
            wskazywać, że nie skrzywdziły w przeszłości żadnego dziecka.
          </p>
          <p>
            2. Każdą osobę zatrudnianą przez Hotel Miodowa*** do prac związanych
            z edukacją, wypoczynkiem i opieką nad dziećmi należy obowiązkowo
            sprawdzić w Rejestrze Sprawców Przestępstw na Tle Seksualnym.
            Sprawdzenie osoby w Rejestrze odbywa się poprzez wydruk wyników
            wyszukiwania osoby w Rejestrze z dostępem ograniczonym, który
            następnie wkładany jest do akt osobowych osoby sprawdzanej.
            Sprawdzenie należy powtarzać co roku.
          </p>
          <p>
            3. Wszyscy pracownicy zatrudnieni do pracy z dziećmi, w tym osoby,
            które mogą mieć potencjalny kontakt z dziećmi, powinny złożyć
            oświadczenie o braku karalności i o nietoczeniu się postępowań o
            czyny przeciwko dzieciom.
          </p>
          <h3>
            ZASADY ZAPEWNIAJĄCE BEZPIECZNE RELACJE MIĘDZY MAŁOLETNIM, A
            PERSONELEM HOTELU
          </h3>
          <p>
            1. Personel zobowiązany jest do utrzymywania profesjonalnej relacji
            z dziećmi i każdorazowego rozważenia, czy twoja reakcja, komunikat,
            bądź działanie wobec dziecka są adekwatne do sytuacji, bezpieczne,
            uzasadnione i sprawiedliwe. Działa w sposób otwarty i przejrzysty
            dla innych, aby zminimalizować ryzyko błędnej interpretacji swojego
            zachowania.
          </p>
          <p>2. Komunikacja i współpraca z dziećmi:</p>
          <p className="podpunkt">
            a). W komunikacji z dziećmi zachowują cierpliwość i szacunek.
          </p>
          <p className="podpunkt">
            b). Słuchają uważnie dzieci i udzielają im odpowiedzi adekwatnych do
            ich wieku i danej sytuacji.
          </p>
          <p className="podpunkt">
            c). Nie wolno zawstydzać, upokarzać, lekceważyć i obrażać dziecka
            oraz krzyczeć na dziecko.
          </p>
          <p className="podpunkt">
            d). Nie wolno ujawniać informacji wrażliwych dotyczących dziecka
            wobec osób nieuprawnionych, w tym wobec innych dzieci.
          </p>
          <p className="podpunkt">e). Szanuje prawo dziecka do prywatności.</p>
          <p className="podpunkt">
            f). Nie wolno zachowywać się w obecności dzieci w sposób
            niestosowny.
          </p>
        </div>
      ) : (
        <div className="content">
          <h2>CHILD PROTECTION PROCEDURES AT HOTEL MIODOWA***</h2>
          <h3>
            In view of the guidelines of the United Nations regarding business
            and human rights, recognizing the important role of business in
            ensuring respect for children's rights, particularly the right to
            protect their dignity and freedom from all forms of harm, Hotel
            Miodowa*** adopts this document as a model of principles and
            procedures in the event of suspicion that a child staying at the
            hotel is being harmed and to prevent such risks. We will implement
            the child protection policy in our hotel through these rules.
          </h3>
          <p>
            1. Hotel Miodowa*** operates with the utmost respect for human
            rights, especially children's rights, as they are particularly
            vulnerable to harm.
          </p>
          <p>
            2. Hotel Miodowa*** recognizes its role in conducting socially
            responsible business and promoting desirable social attitudes.
          </p>
          <p>
            3. Hotel Miodowa*** emphasizes the importance of the legal and
            social obligation to report any suspected crime against children to
            law enforcement authorities and commits to training its staff in
            this area.
          </p>
          <p>
            4. Hotel Miodowa*** commits to educating staff on circumstances
            indicating that a child staying at the hotel may be harmed, as well
            as ways to react promptly and appropriately in such situations.
          </p>
          <p>
            5. One of the forms of effective prevention of child abuse is
            identifying the child staying at the hotel and his/her relationship
            with the adult accompanying him/her. The staff will take all
            possible steps to conduct the identification of the child and
            his/her relationship with the adult they are with at the hotel.
          </p>
          <h3>PROCEDURE IN CASE OF SUSPECTED CHILD ABUSE</h3>
          <p>
            1. Whenever possible, the child and their relationship with the
            adult staying at the hotel should be identified.
          </p>
          <p>
            2. In unusual and/or suspicious situations indicating possible child
            abuse, identification is mandatory and carried out by the reception
            staff.
          </p>
          <p>
            3. To identify the child and their relationship with the adult
            accompanying them, the following steps should be taken:
          </p>
          <p className="podpunkt">
            a). Ask for the identity of the child and their relationship to the
            adult they are with at the hotel. This can be done by requesting the
            child's identification document or another document confirming that
            the adult has the right to care for the child at the hotel. A list
            of example documents is provided in the footnote below. If there is
            no identification document, ask for the child's details (name,
            surname, address, PESEL number).
          </p>
          <p className="podpunkt">
            b). If there are no documents indicating the relationship between
            the child and the adult, ask both the adult and the child about
            their relationship.
          </p>
          <p className="podpunkt">
            c). If the adult is not the child's parent or legal guardian, ask if
            they have a document proving parental consent for the trip with the
            child (e.g., a written statement).
          </p>
          <p className="podpunkt">
            d). If the adult does not have a parental consent document, request
            a phone number for the parents/legal guardians to confirm the
            child's stay at the hotel with the adult, with the knowledge and
            consent of the parents/legal guardians.
          </p>
          <p>
            4. If the adult refuses to show the child's identification and/or
            indicate the relationship, explain that the procedure is in place to
            ensure the safety of children at Hotel Miodowa*** and was developed
            in consultation with NGOs working in this area.
          </p>
          <p>
            5. Once the matter is resolved positively, thank them for their time
            to ensure the child is in good care, and once again emphasize that
            the procedure is in place to ensure the children's safety.
          </p>
          <p>
            6. If the conversation does not dispel doubts regarding the adult
            and their intentions towards the child, discreetly notify the
            supervisor and security staff (if present at the hotel). To avoid
            raising suspicion, you can, for example, mention the need to use
            equipment behind the reception desk, asking the adult to wait with
            the child in the lobby, restaurant, or another area.
          </p>
          <p>
            7. From the moment the first doubts arise, both the child and the
            adult should be under constant observation by the staff and not left
            alone.
          </p>
          <p>
            8. The notified supervisor will decide whether to inform the police
            or, in case of doubt, take over the conversation with the suspicious
            adult to gather further explanations.
          </p>
          <p>
            9. If the conversation confirms a belief of attempted or committed
            abuse against the child, the supervisor will notify the police. Then
            the procedure for circumstances indicating child abuse is followed.
          </p>
          <p>
            10. If other Hotel Miodowa*** employees (e.g., cleaning service,
            room service, bar and restaurant staff, relaxation area, security,
            etc.) witness unusual and/or suspicious situations, they should
            immediately notify their supervisor, who will decide on appropriate
            actions.
          </p>
          <p>
            11. Depending on the situation and location, the supervisor will
            verify whether the suspicion of child abuse is justified. In this
            regard, they will select appropriate measures to clarify the
            situation or decide to intervene and notify the police.
          </p>
          <h3>PROCEDURE FOR CIRCUMSTANCES INDICATING CHILD ABUSE</h3>
          <p>
            1. If there is a reasonable suspicion that a child staying at the
            hotel is being harmed, the police must be notified immediately by
            calling 112 and describing the circumstances. Depending on the
            situation's dynamics, the call should be made by the direct witness
            (employee/supervisor). If the notifier is an employee, they should
            also inform their supervisor.
          </p>
          <p>2. Reasonable suspicion of child abuse exists when:</p>
          <p className="podpunkt">
            a). The child has disclosed to an employee that they are being
            abused,
          </p>
          <p className="podpunkt">b). The employee has observed abuse,</p>
          <p className="podpunkt">
            c). The child has visible signs of abuse (e.g., scratches, bruises),
            and when asked, responds inconsistently and/or chaotically or
            becomes embarrassed, or other circumstances suggest abuse, such as
            finding child pornography in the adult's room.
          </p>
          <p>
            3. In this situation, the child and the person suspected of abusing
            the child should not be allowed to leave the premises.
          </p>
          <p>
            4. In justified cases, a citizen's arrest of the suspected person
            may be carried out. In this case, until the police arrive, the
            person should be supervised by two employees in a separate room away
            from the view of other guests.
          </p>
          <p>
            5. In every case, the safety of the child must be ensured. The child
            should remain under the care of an employee until the police arrive.
          </p>
          <p>
            6. If there is reasonable suspicion of a crime involving the child's
            contact with the perpetrator's biological material (semen, saliva,
            skin cells), prevent the child from washing or eating/drinking until
            the police arrive.
          </p>
          <p>
            7. After the child is taken by the police, secure the surveillance
            footage and other relevant evidence (e.g., documents) related to the
            incident, and at the police or prosecutor's request, send a copy by
            registered mail or deliver it in person.
          </p>
          <p>
            8. After the intervention, the incident should be documented in an
            event log or another document designated for this purpose.
          </p>
          <h3>HIRING PEOPLE TO WORK WITH CHILDREN</h3>
          <p>
            1. All individuals working with children must be safe for them,
            meaning, among other things, that their employment history should
            show they have never harmed any child.
          </p>
          <p>
            2. Every person hired by Hotel Miodowa*** to work in education,
            recreation, and childcare must be checked in the Register of Sexual
            Offenders. This check is done by printing the search results from
            the restricted-access register and placing them in the checked
            person's personnel file. The check should be repeated every year.
          </p>
          <p>
            3. All employees working with children, including those who may have
            potential contact with children, should provide a statement of no
            criminal record and no ongoing proceedings for offenses against
            children.
          </p>
          <h3>
            RULES FOR ENSURING SAFE RELATIONS BETWEEN MINORS AND HOTEL STAFF
          </h3>
          <p>
            1. The staff is obliged to maintain a professional relationship with
            children and to always consider whether their reaction,
            communication, or actions toward the child are appropriate to the
            situation, safe, justified, and fair. They act in an open and
            transparent manner to minimize the risk of their behavior being
            misinterpreted.
          </p>
          <p>2. Communication and cooperation with children:</p>
          <p className="podpunkt">
            a). In communication with children, staff should remain patient and
            respectful.
          </p>
          <p className="podpunkt">
            b). They listen carefully to children and provide responses
            appropriate to the child's needs and understanding.
          </p>
          <p className="podpunkt">
            c). They do not ridicule, insult, or disrespect children.
          </p>
          <p>
            3. Employees do not take photos or videos of children without
            parental consent.
          </p>
          <p>
            4. Employees do not take children into rooms without parental or
            guardian presence.
          </p>
          <p>
            5. The staff may not invite children into any rooms without parental
            or guardian supervision or consent.
          </p>
          <p>
            6. The hotel staff does not provide or arrange transportation for
            minors without the written consent of their parents or guardians.
          </p>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  padding-top: 13vh;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    padding-top: 12vh;
  }
  h4 {
    text-decoration: uppercase;
    font-size: 2rem;
    color: var(--secondaryColor2);
    text-align: center;
    margin: 10vh auto 5vh;
  }
  .title {
    padding-top: 10vh;
    padding-bottom: 10vh;
    width: 100vw;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #444;
    text-shadow: none;
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      padding-top: 8vh;
      padding-bottom: 8vh;
    }
    h2 {
      color: var(--secondaryColor);
      letter-spacing: 5px;
      text-align: center;
      font-size: 2.6rem;
    }
    @media screen and (max-width: 800px) {
      h1:nth-of-type(2) {
        display: none;
      }
      h2:nth-of-type(2) {
        width: 100%;
        margin-top: 5vh;
      }
      h2:nth-of-type(3) {
        display: none;
      }
      h3:nth-of-type(2) {
        display: none;
      }
      h1 {
        font-size: 1.8rem;
      }
      h2 {
        font-size: 1.8rem;
        letter-spacing: 5px;
      }
      h3 {
        font-size: 1.8rem;
      }
    }
  }
  .content {
    width: 80vw;
    margin: 15vh auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: var(--textFont);
    h2,
    h3 {
      margin: 5vh auto;
      align-self: center;
      color: var(--secondaryColor2);
      text-align: center;
      font-family: var(--buttonFont);
    }

    p {
      margin: 1vh auto 1vh 0;
      letter-spacing: 1.3;
    }
    .podpunkt {
      margin-left: 20px;
    }
  }
`;

export default ChildrenCarePage;
